@import '~bootstrap/scss/bootstrap.scss';
@use "sass:map";

body, html {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.App {
  width: 100vw;
  height: 100vh;
}

/* Bootstrap overrides */
@import 'base.scss';
@import 'colours.scss';
@import 'typography.scss';
@import 'components.scss';
/* Bootstrap overrides */
