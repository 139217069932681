.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 36px;
  height: 100vh;
  padding: 60px 30px;
  text-align: center;
}

p {
  max-width: 90%;
}

img {
  width: 100%;
  max-width: 680px;
  height: auto;
}

@media screen and (min-width: 768px) {
  p {
    max-width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  p {
    width: 75%;
    max-width: 1000px;
  }  

  .container {
    justify-content: center;
  }
}