@font-face {
  font-family: 'GTFlexaLight';
  src: url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Light.woff2') format('woff2'),
    url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Light.woff') format('woff'),
    url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTFlexaMedium';
  src: url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Medium.woff2') format('woff2'),
    url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Medium.woff') format('woff'),
    url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GTFlexaRegular';
  src: url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Regular.woff2') format('woff2'),
    url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Regular.woff') format('woff'),
    url('../assets/fonts/GTFlexa/GT-Flexa-Standard-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'GTFlexaRegular', Regular, Helvetica, sans-serif;
}

p {
  margin-bottom: 0px;
}

$font-weights: (
  'regular': 400,
  'medium': 500,
  'bold': 700,
);

/* Typography overrides */
.display-1 {
  font-size: 4rem;
  line-height: 3.75rem;
  font-weight: map-get($font-weights, 'regular');
  color: map-get($font-weights, 'darkPurple');
}

h1 {
  font-size: 3.5rem;
  line-height: 3.5rem;
  font-weight: map-get($font-weights, 'regular');
  color: map-get($font-weights, 'darkPurple');
}

h2 {
  font-size: 3rem;
  line-height: 3.25rem;
  font-weight: map-get($font-weights, 'regular');
  color: map-get($font-weights, 'darkPurple');
}

h3 {
  font-size: 2rem;
  line-height: 2.125rem;
  font-weight: map-get($font-weights, 'regular');
  color: map-get($font-weights, 'darkPurple');
}

.h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: map-get($font-weights, 'regular');
  color: map-get($font-weights, 'darkPurple');
  font-family: 'GTFlexaMedium';
}

.body1 {
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: map-get($font-weights, 'regular');
  font-family: 'GTFlexaLight';
  color: map-get($font-weights, 'darkPurple');
}

.body2 {
  font-size: 1rem;
  line-height: 1.125rem;
  font-weight: map-get($font-weights, 'regular');
  color: map-get($font-weights, 'darkPurple');
}

.body3 {
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: map-get($font-weights, 'regular');
  color: map-get($font-weights, 'darkPurple');
}
/* Typography overrides */
