$primary-colours: (
  'white': #ffffff,
  'black': #000000,
  'cultOrange': #ff9675,
  'darkPurple': #1d1d1d,
  'cultDark': #1c1c20,
  'cultLightGreen': #cce3e0,
  'cultDarkGreen': #1d4854,
  'cultYellow': #ebd286,
  'cultPurple': #cacbfb,
  'grey': #f0ebe6,
  'textDarkGrey': #f1f4f5,
  'textGrey': #606063,
  'textLightGreen': #bbc8cc,
  'cultBlack': #1d1d1d,
);
