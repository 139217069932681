/* inputs */
input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid rgba(29, 29, 29, 0.25);
  border-radius: 50%;
  box-sizing: border-box;
}

input[type='radio']:checked {
  background-color: map-get($primary-colours, 'cultOrange');
  border: 2px solid white;
  box-shadow: 0px 0px 0px 1px rgba(29, 29, 29, 0.25);
}

input.amount-input {
  border: none;
  border-bottom: 1px dashed map-get($primary-colours, 'cultOrange');
  margin-bottom: 20px;
  padding: 0px;
  margin-left: 7px;
  margin-right: 0px;
  color: #000;
  outline: none;
  box-sizing: border-box;
}

input.amount-input:focus,
input.amount-input * {
  outline: none;
  border: none;
  border-bottom: 1px dashed map-get($primary-colours, 'cultOrange');
  box-shadow: none;
}

.required:after {
  content: ' *';
  color: red;
}

/* Base icon styles */
i {
  height: 13px;
  width: 13px;
}

/* Base link styles */
a {
  color: map-get($primary-colours, 'cultBlack');
}

/* Base button styles */
button {
  cursor: pointer;
}

.btn {
  border-radius: 30px;
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: 39px;
  letter-spacing: 0.05em;
}

.btn:active:focus,
.btn:focus {
  box-shadow: none;
}

.btn-lg {
  height: 52px;
}

.btn-sm {
  height: 43px;
}

/* Button variants & their states */
/* Primary orange */
.btn-primary {
  color: map-get($primary-colours, 'darkPurple');
  background-color: map-get($primary-colours, 'cultOrange');
  border-width: 0px;
}

.btn-primary:hover {
  background-color: #e88a6c;
  color: black;
}

.btn-primary:active:focus {
  background-color: #ffc5b3;
  color: black;
}

.btn-primary:focus {
  background-color: map-get($primary-colours, 'cultOrange');
  color: black;
}
/* Primary orange */

/* Dark */
.btn-dark {
  color: #fff;
  background-color: map-get($primary-colours, 'darkPurple');
  border-width: 0px;
}

.btn-dark:hover {
  background-color: #3e3e41;
}

.btn-dark:active:focus {
  background-color: #a5a5a5;
}
/* Dark */

/* Primary-outlined */
.btn-primary-outlined {
  border-color: map-get($primary-colours, 'darkPurple');
}
/* Primary-outlined */

/* Form */
.form-control {
  border-radius: 0px;
  font-size: 1.25rem;
  height: 52px;
  border-color: map-get($primary-colours, 'darkPurple');
}

.form-control:focus {
  border: 1px solid #ff8b67;
  box-shadow: 0px 0px 6px #ff8b67;
}

.form-label {
  color: map-get($primary-colours, 'darkPurple');
}

/* Tables */
/* DivTable.com */
.table {
  display: table;
  width: 100%;
}

.table-row {
  display: table-row;
}

.table-heading {
  background-color: #eee;
  display: table-header-group;
}

.table-cell,
.table-head {
  border: 1px solid #999999;
  display: table-cell;
  padding: 3px 10px;
}

.table-footer {
  background-color: #eee;
  display: table-footer-group;
}

.table-body {
  display: table-row-group;
}

@media (min-width: 768px) {
  .btn {
    max-width: 152px;
  }
}
