.bold {
  font-weight: bold;
}

.content-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.left-container,
.right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex-basis: 100%;
  flex: 50%;
}

.left-container {
  position: relative;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
  padding-right: 32px;
}

.ta-l {
  text-align: left;
}

.ta-r {
  text-align: right;
}

.ta-c {
  text-align: center;
}

@media (max-width: 767px) {
  .left-container {    
    flex: 1 2 auto;
    padding-top: 40px;
  }

  .right-container {   
    flex: 1 1 auto;
  }

  .left-container,
  .right-container {
    width: 100%;
  }

  .sort-order-first {
    order: 1;
  }

  .sort-order-second {
    order: 2;
  }
}

@media (min-width: 768px) {
  .content-container {
    height: 100%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
}

@media (min-width: 992px) and (max-width: 1379px) {
}

@media (min-width: 1380px) {
}
